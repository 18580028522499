export const metaData = {
  about: {
    maNavi: 'M&Aナビについて',
    seller: '売り手の方',
    sellerContacts: 'M&Aについて相談',
    buyer: '買い手の方',
  },
  projects: '売却案件一覧',
  projectList: 'M&A案件一覧',
  featureList: 'M&A案件特集',
  projectsHistory: '閲覧履歴',
  columns: 'コラム',
  maColumns: 'M&A事業承継コラム',
  maFaq: 'M&Aのお悩み相談室',
  notices: 'お知らせ',
  faq: 'よくあるご質問',
  glossaries: '用語集',
  contact: 'お問い合わせ',
  corp: '運営会社',
  supportSpecialists: '支援専門家一覧',
  termOfServices: '利用規約',
  informationProtection: '個人情報保護について',
  copyrightText: 'Copyright@NISHI CHUGOKU SHINKIN BANK. All Rights Reserved.',
  bihokuCopyright: 'Copyright@BihokuShinkinBank. All Rights Reserved.',
}
