import { ObjectType } from 'types/object'

export const coordinatorKind: ObjectType = {
  1: '総合アドバイザー',
  2: '専門アドバイザー',
}

export const COORDINATOR_PUBLISH_MARECORD_MAX = 1000000

export const CURRENT_PROJECT_ID = 'currentProjectId'
