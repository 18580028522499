export const footerMatching = {
  industries: '業種',
  regions: 'エリア',
  prefectures: '都道府県',
  salesPrice: '売却価格',
  termOfServices: '利用規約',
  privacyPolicy: '個人情報保護方針',
  securityPolicy: '情報セキュリティーポリシー',
  copyRight: '© 2018 M&Aナビ',
  electronicPublicNotice: '電子公告',
}
