import React, { useCallback, useMemo, useState } from 'react'
import Link from 'next/link'
import {
  Button,
  Box,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Stack,
  Typography,
  useTheme,
  styled,
} from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { useRouter } from 'next/router'
import { ImageFlux } from 'components/elements/ImageFlux'
import { headerMatching as t } from 'public/locales/ja/components/elements/headerMatching'
import { UserRole } from 'types/user'
import { MyPageMenu } from 'components/elements/MyPageMenu'
import { DesktopOnly } from 'components/elements/DesktopOnly'
import { MobileOnly } from 'components/elements/MobileOnly'

type HeaderMatchingProps = {
  isNaviDisplayed?: boolean
  logoHref?: string
  role?: UserRole.Seller | UserRole.Buyer | UserRole.Coordinator | null
  sellerProjectStatus?: string
}

type MenuItem = {
  title: string
  link: string
  children?: MenuItem[]
  currentProjectLink?: (id: string) => string
  isReadMessage?: boolean
}

type About = {
  first: MenuItem[]
  second: MenuItem[]
}

type SearchKeyword = {
  title: string
  children: MenuItem[]
}

export type RoleBaseMenu = MenuItem & {
  status?: string
}

export const HeaderMatching: React.FC<React.PropsWithChildren<HeaderMatchingProps>> = ({
  isNaviDisplayed = true,
  role,
  logoHref,
}) => {
  const theme = useTheme()
  const router = useRouter()
  const [openDrawer, setDrawer] = useState<boolean>(false)
  const [openSearchDrawer, setSearchDrawer] = useState<boolean>(false)
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false)
  const [searchWord, setSearchWord] = useState<string>('')
  const [isSearchWordCompositionEnd, setIsSearchWordCompositionEnd] = useState<boolean>(false)
  const [hoveredMenuTitle, setHoveredMenuTitle] = useState<string | null>(null)

  const listMenuPublic = useMemo<MenuItem[]>(
    () => [
      {
        title: t.metaData.about.seller,
        link: '/about/seller',
        children: [
          { title: t.metaData.about.seller, link: '/about/seller' },
          { title: t.metaData.about.sellerContacts, link: '/seller-contacts' },
        ],
      },
      {
        title: t.metaData.about.buyer,
        link: '/about/buyer',
      },
      {
        title: t.metaData.projects,
        link: '/projects',
        children: [
          { title: t.metaData.projectList, link: '/projects' },
          { title: t.metaData.featureList, link: '/features' },
          { title: t.metaData.projectsHistory, link: '/recent_viewed_projects' },
        ],
      },
      {
        title: t.metaData.about.maNavi,
        link: '/about',
      },
      {
        title: t.metaData.columns,
        link: 'https://ma-navigator.com/columns',
        children: [
          { title: t.metaData.maColumns, link: 'https://ma-navigator.com/columns' },
          { title: t.metaData.maFaq, link: 'https://ma-navigator.com/columns/faq' },
        ],
      },
    ],
    []
  )

  const listNavigationMenu = useMemo<About>(
    () => ({
      first: [
        {
          title: t.metaData.contact,
          link: '/contacts',
        },
        {
          title: t.metaData.faq,
          link: '/faq',
        },
        {
          title: t.metaData.glossaries,
          link: '/glossaries',
        },
        {
          title: t.metaData.corp,
          link: '/corp',
        },
      ],
      second: [
        {
          title: t.footerMatching.termOfServices,
          link: '/terms',
        },
        {
          title: t.footerMatching.privacyPolicy,
          link: '/privacy',
        },
        {
          title: t.footerMatching.securityPolicy,
          link: '/2021_security_policy.pdf',
        },
      ],
    }),
    []
  )

  const listSearchKeywords = useMemo<SearchKeyword[]>(
    () => [
      {
        title: t.listSearchKeywords.title,
        children: [
          {
            title: t.listSearchKeywords.surplus,
            link: `/projects?keywords=${t.listSearchKeywords.surplus}`,
          },
          {
            title: t.listSearchKeywords.pets,
            link: `/projects?keywords=${t.listSearchKeywords.pets}`,
          },
          {
            title: t.listSearchKeywords.beauty,
            link: `/projects?keywords=${t.listSearchKeywords.beauty}`,
          },
          {
            title: t.listSearchKeywords.overseas,
            link: `/projects?keywords=${t.listSearchKeywords.overseas}`,
          },
          {
            title: t.listSearchKeywords.location,
            link: `/projects?keywords=${t.listSearchKeywords.location}`,
          },
        ],
      },
      {
        title: t.saleDeal.title,
        children: [
          {
            title: t.saleDeal.listOfSales,
            link: '/projects',
          },
          {
            title: t.saleDeal.history,
            link: '/recent_viewed_projects',
          },
        ],
      },
    ],
    []
  )

  const handleToggleDrawer = useCallback((toggle: boolean) => {
    setDrawer(toggle)
    setTimeout(() => setIsSearchOpen(false))
  }, [])

  const handleToggleSearchDrawer = useCallback((toggle: boolean) => {
    setSearchDrawer(toggle)
    setTimeout(() => setIsSearchOpen(false))
  }, [])

  const handleToggleSearch = useCallback((toggle: boolean) => {
    if (!toggle) setSearchWord('')
    setIsSearchOpen(toggle)
  }, [])

  const handleChangeSearchWord = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchWord(e.target.value)
  }, [])

  const handleClearSearchWord = useCallback(() => {
    setSearchWord('')
  }, [])

  const handleSearch = useCallback(() => {
    if (!searchWord) return
    setSearchDrawer(false)
    return router.push(`/projects?keywords=${searchWord}`)
  }, [router, searchWord])

  const handleKeyUpSearchWord = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && !isSearchWordCompositionEnd) {
        handleSearch()
      }
      setIsSearchWordCompositionEnd(false)
    },
    [isSearchWordCompositionEnd, handleSearch]
  )

  const renderListSubMenu = useCallback(
    (childrenMenu: MenuItem[]) => {
      if (!childrenMenu) return null

      return (
        <SubMenuWrapper childrencount={childrenMenu.length}>
          {childrenMenu.map((item, index) => (
            <Link href={item.link} passHref key={index} legacyBehavior>
              <LinkMenuWrapper onClick={() => handleToggleDrawer(false)}>{item.title}</LinkMenuWrapper>
            </Link>
          ))}
        </SubMenuWrapper>
      )
    },
    [handleToggleDrawer]
  )

  const renderListSubMenuMobile = useCallback((subMenu: MenuItem[], onClickHandler: () => void) => {
    return (
      <Stack>
        {subMenu.map((item) => (
          <Link key={item.title} href={item.link} passHref>
            <span onClick={onClickHandler}>
              <Typography variant={'body1'} sx={{ lineHeight: '40px' }}>
                {item.title}
              </Typography>
            </span>
          </Link>
        ))}
      </Stack>
    )
  }, [])

  const renderNavigationRoleBased = useCallback(() => {
    if (role === UserRole.Buyer || role === UserRole.Seller || role === UserRole.Coordinator) return <MyPageMenu />

    return (
      <>
        <MobileOnly>
          <Link href={'/users/sign_in'} passHref legacyBehavior>
            <Button
              sx={{ paddingLeft: theme.spacing(1.5), paddingRight: theme.spacing(1.5) }}
              variant="contained"
              size="large"
            >
              <Typography fontWeight={700}>{t.button.signIn}</Typography>
            </Button>
          </Link>
        </MobileOnly>
        <DesktopOnly>
          <MenuItemStyled>
            <Link href={'/users/sign_in'} passHref legacyBehavior>
              <LinkMenuWrapper>{t.button.signIn}</LinkMenuWrapper>
            </Link>
          </MenuItemStyled>
        </DesktopOnly>
        <DesktopOnly>
          <Link href={'/users/sign_up'} passHref legacyBehavior>
            <RegisterButton variant="contained" size="large">
              <Typography fontWeight={700}>{t.button.register}</Typography>
            </RegisterButton>
          </Link>
        </DesktopOnly>
      </>
    )
  }, [role, theme])

  const mobileSearchForm = (
    <SearchFromWrapper direction={'row'} columnGap={2}>
      <FormControl sx={{ flex: 1 }}>
        <StyledOutlinedInput
          name="searchForm"
          value={searchWord}
          placeholder={t.search.placeHolderMobile}
          onChange={handleChangeSearchWord}
          onCompositionEnd={() => setIsSearchWordCompositionEnd(true)}
          onKeyUp={handleKeyUpSearchWord}
          endAdornment={
            <InputAdornment position={'end'}>
              <IconButton onClick={handleClearSearchWord}>
                <CloseIcon htmlColor={theme.tertiary.lightness1} />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <StyledSearchButton variant={'contained'} startIcon={<SearchIcon />} onClick={handleSearch}>
        <Typography fontWeight={700} variant={'body2'}>
          {t.button.search}
        </Typography>
      </StyledSearchButton>
    </SearchFromWrapper>
  )

  const desktopSearchForm = (
    <Stack direction={'row'} columnGap={2} alignItems={'center'}>
      <FormControl>
        <StyledDesktopSearchInput
          name="desktopSearchInput"
          value={searchWord}
          placeholder={t.search.placeHolder}
          onChange={handleChangeSearchWord}
          onCompositionEnd={() => setIsSearchWordCompositionEnd(true)}
          onKeyUp={handleKeyUpSearchWord}
          endAdornment={
            <InputAdornment position={'end'}>
              <IconButton onClick={handleClearSearchWord}>
                <CloseIcon htmlColor={theme.tertiary.darkness1} />
              </IconButton>
              <Divider orientation={'vertical'} sx={{ mx: 0.5, height: 28 }} />
              <IconButton onClick={handleSearch}>
                <SearchIcon htmlColor={theme.tertiary.darkness1} />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <Stack rowGap={0.5} alignItems={'center'}>
        <CancelSearchButton onClick={() => handleToggleSearch(false)}>
          <CloseIcon htmlColor={theme.palette.common.white} />
        </CancelSearchButton>
        <Typography sx={{ fontSize: 10 }}>{t.button.cancel}</Typography>
      </Stack>
    </Stack>
  )

  return (
    <ContainerStyled>
      <HeaderWrapperStyled direction={'row'} alignItems={'center'}>
        <Stack direction={'row'} width={'100%'} justifyContent={'space-between'}>
          <Stack direction={'row'} alignItems={'center'}>
            {isNaviDisplayed && (
              <Stack
                sx={{ display: { sm: 'flex', md: 'none' } }}
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <IconButton
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 1 }}
                  component="button"
                  onClick={(e) => {
                    e.preventDefault()
                    handleToggleDrawer(true)
                  }}
                  style={{
                    touchAction: 'manipulation',
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Stack>
            )}
            <Link href={logoHref || '/'} passHref legacyBehavior>
              <LinkLogoWrapper>
                <ImageFlux
                  loading="eager"
                  src={'/images/logo_ma_navi.png'}
                  alt={t.altLogo}
                  width={'148'}
                  height={'31'}
                />
              </LinkLogoWrapper>
            </Link>
          </Stack>
          {isNaviDisplayed && !isSearchOpen && (
            <>
              <MenuListStyled>
                {listMenuPublic.map((menu, index) => (
                  <MenuItemStyled
                    key={index}
                    onMouseEnter={() => setHoveredMenuTitle(menu?.title)}
                    onMouseLeave={() => setHoveredMenuTitle(null)}
                  >
                    <Link href={menu.link} passHref legacyBehavior>
                      <LinkMenuWrapper>
                        {menu.title}
                        {menu?.children && <ArrowDropDownIcon />}
                      </LinkMenuWrapper>
                    </Link>
                    {hoveredMenuTitle === menu.title && menu?.children && renderListSubMenu(menu.children)}
                  </MenuItemStyled>
                ))}
              </MenuListStyled>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'flex-end'}
                spacing={1}
                sx={{ sm: { flex: 1 } }}
              >
                <MobileOnly>
                  <IconButton
                    sx={{ color: theme.tertiary.darkness1 }}
                    component="button"
                    aria-label="search"
                    onClick={(e) => {
                      e.preventDefault()
                      handleToggleSearchDrawer(true)
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </MobileOnly>
                <DesktopOnly>
                  <IconButton
                    sx={{ color: theme.tertiary.darkness1 }}
                    component="button"
                    aria-label="search"
                    onClick={() => handleToggleSearch(true)}
                  >
                    <SearchIcon />
                  </IconButton>
                </DesktopOnly>
                {renderNavigationRoleBased()}
              </Stack>
            </>
          )}
          {isSearchOpen && <DesktopOnly>{desktopSearchForm}</DesktopOnly>}
        </Stack>
      </HeaderWrapperStyled>
      {openDrawer && (
        <Box sx={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0 }}>
          <DrawerContainer>
            <HeaderDrawer direction={'row'}>
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <IconButton
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 1 }}
                  component="button"
                  onClick={() => setDrawer(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Box onClick={() => setDrawer(false)} sx={{ display: 'flex', alignItems: 'center' }}>
                <Link href={'/'} passHref legacyBehavior>
                  <LinkLogoWrapper>
                    <ImageFlux
                      loading="lazy"
                      src={'/images/logo_ma_navi.png'}
                      alt={t.altLogo}
                      width="148"
                      height="31"
                    />
                  </LinkLogoWrapper>
                </Link>
              </Box>
            </HeaderDrawer>
            <ContentDrawer>
              <>
                <Stack pb={6.5} pt={3} justifyContent={'center'}>
                  <Link href={'/users/sign_up'} passHref legacyBehavior>
                    <FreeTryButton variant={'outlined'}>
                      <Typography variant={'h6'}>{t.button.freeTry}</Typography>
                    </FreeTryButton>
                  </Link>
                </Stack>
                <ListPublicMenuContainer rowGap={4}>
                  {listMenuPublic.map((item) => (
                    <Stack key={item.title} rowGap={1}>
                      <Link href={item.link} passHref>
                        <span onClick={() => setDrawer(false)}>
                          <Typography variant={'h6'}>{item.title}</Typography>
                        </span>
                      </Link>
                      {item?.children && renderListSubMenuMobile(item.children, () => setDrawer(false))}
                    </Stack>
                  ))}
                </ListPublicMenuContainer>
              </>
              <Divider />
              <ListNavigationContainer>
                <Stack rowGap={1}>
                  {listNavigationMenu.first.map((item) => (
                    <Link key={item.title} href={item.link} passHref>
                      <span onClick={() => setDrawer(false)}>
                        <Typography variant={'body2'}>{item.title}</Typography>
                      </span>
                    </Link>
                  ))}
                </Stack>
                <Stack rowGap={1}>
                  {listNavigationMenu.second.map((item) => (
                    <Link key={item.title} href={item.link} passHref>
                      <span onClick={() => setDrawer(false)}>
                        <Typography variant={'body2'}>{item.title}</Typography>
                      </span>
                    </Link>
                  ))}
                </Stack>
              </ListNavigationContainer>
            </ContentDrawer>
          </DrawerContainer>
        </Box>
      )}

      {openSearchDrawer && (
        <Box sx={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0 }}>
          <DrawerContainer>
            <HeaderDrawer direction={'row'}>
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <IconButton
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 1 }}
                  component="button"
                  onClick={() => setSearchDrawer(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Box onClick={() => setSearchDrawer(false)} sx={{ display: 'flex', alignItems: 'center' }}>
                <Link href={'/'} passHref legacyBehavior>
                  <LinkLogoWrapper>
                    <ImageFlux
                      loading="lazy"
                      src={'/images/logo_ma_navi.png'}
                      alt={t.altLogo}
                      width="148"
                      height="31"
                    />
                  </LinkLogoWrapper>
                </Link>
              </Box>
            </HeaderDrawer>
            <ContentDrawer>
              <>
                {mobileSearchForm}
                <ListSearchKeywordsWrapper rowGap={4.5}>
                  {listSearchKeywords.map((item) => (
                    <Stack key={item.title} rowGap={1}>
                      <Typography variant={'h6'}>{item.title}</Typography>
                      {renderListSubMenuMobile(item.children, () => setSearchDrawer(false))}
                    </Stack>
                  ))}
                </ListSearchKeywordsWrapper>
              </>
              <Divider />
              <ListNavigationContainer>
                <Stack rowGap={1}>
                  {listNavigationMenu.first.map((item) => (
                    <Link key={item.title} href={item.link} passHref>
                      <span onClick={() => setSearchDrawer(false)}>
                        <Typography variant={'body2'}>{item.title}</Typography>
                      </span>
                    </Link>
                  ))}
                </Stack>
                <Stack rowGap={1}>
                  {listNavigationMenu.second.map((item) => (
                    <Link key={item.title} href={item.link} passHref>
                      <span onClick={() => setSearchDrawer(false)}>
                        <Typography variant={'body2'}>{item.title}</Typography>
                      </span>
                    </Link>
                  ))}
                </Stack>
              </ListNavigationContainer>
            </ContentDrawer>
          </DrawerContainer>
        </Box>
      )}
    </ContainerStyled>
  )
}

const ContainerStyled = styled('header')(
  ({ theme }) => `
  height: ${theme.spacing(10)};
  padding: 0 ${theme.spacing(2.5)};
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  background: ${theme.palette.common.white};
  box-shadow: inset 0 -1px 0 ${theme.tertiary.lightness2};
  ${theme.breakpoints.down('sm')} {
    padding: 0 ${theme.spacing(2)};
  }
`
)

const HeaderWrapperStyled = styled(Stack)(
  () => `
  height: 100%;
`
)

const MenuListStyled = styled('div')(
  ({ theme }) => `
  padding-left: ${theme.spacing(5)};
  display: flex;
  align-items: center;
  flex: 1;
  ${theme.breakpoints.down(1090)} {
    display: none;
  }
`
)
type SubMenuWrapperProps = {
  childrencount?: number
}
const SubMenuWrapper = styled(Typography)<SubMenuWrapperProps>(
  ({ theme, childrencount }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1.5)};
  position: absolute;
  bottom: -${childrencount ? theme.spacing(childrencount * 4) : 0};
  left: 0;
  background-color: ${theme.palette.common.white};
  border: 1px solid ${theme.grey.lightness2};
  box-shadow: 2px 2px 4px 2px #eee6;
  border-radius: 8px;
  min-width: 161px;
  width: max-content;
  font-weight: 700;
  padding: ${theme.spacing(1.5)} ${theme.spacing(3)};
  z-index: 10001;
`
)

const MenuItemStyled = styled(Box)(
  ({ theme }) => `
  padding-left: ${theme.spacing(1.5)};
  padding-right: ${theme.spacing(1.5)};
  font-weight: 700;
  font-size: 14px;
  position: relative;
  height: 80px;
`
)

const LinkLogoWrapper = styled('a')(
  ({ theme }) => `
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  img {
    width: 148px;
    height: auto;
  }
  ${theme.breakpoints.down('sm')} {
    img {
      max-width: 113px;
    }
  }
  ${theme.breakpoints.down(360)} {
    img {
      max-width: 93px;
    }
  }
`
)

const LinkMenuWrapper = styled('a')(
  ({ theme }) => `
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  text-decoration: none;
  color: ${theme.tertiary.darkness2};
  height: 100%;
`
)

const RegisterButton = styled(Button)`
  height: 54px;
  min-width: 168px;
  border-radius: 4px;
`

const DrawerContainer = styled('div')(
  ({ theme }) => `
  background: ${theme.palette.common.white};
  height: 100%;
`
)

const HeaderDrawer = styled(Stack)(
  ({ theme }) => `
  width: 100%;
  height: ${theme.spacing(10)};
  padding: 0 ${theme.spacing(2)};
  position: fixed;
  z-index: 9999;
  top: 0;
  background: ${theme.palette.common.white};
`
)

const ContentDrawer = styled(Stack)(
  ({ theme }) => `
  padding-top: ${theme.spacing(10)};
`
)

const ListPublicMenuContainer = styled(Stack)(
  ({ theme }) => `
  padding: 0 ${theme.spacing(5)} ${theme.spacing(11.5)} ${theme.spacing(5)};
  a {
    color: ${theme.tertiary.darkness3};
    text-decoration: none;
  }
`
)

const ListNavigationContainer = styled(Stack)(
  ({ theme }) => `
  flex-direction: row;
  column-gap: ${theme.spacing(4)};
  padding: ${theme.spacing(3)} ${theme.spacing(5)};
  a {
    color: ${theme.tertiary.darkness3};
    text-decoration: none;
  }
`
)

const SearchFromWrapper = styled(Stack)(
  ({ theme }) => `
  padding: ${theme.spacing(2)} ${theme.spacing(2.5)} ${theme.spacing(6.5)} ${theme.spacing(2.5)};
`
)

const StyledOutlinedInput = styled(OutlinedInput)(
  ({ theme }) => `
  background: ${theme.grey.lightness4};
  input {
    padding: ${theme.spacing(1.5)} ${theme.spacing(2)};
    &::placeholder {
      color: ${theme.tertiary.lightness2};
      opacity: 1;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${theme.tertiary.lightness2};
  }

 ${theme.breakpoints.down('sm')} {
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
 }
`
)

const StyledDesktopSearchInput = styled(StyledOutlinedInput)(
  ({ theme }) => `
  min-width: 517px;
  ${theme.breakpoints.down(830)} {
    min-width: 340px;
  }
`
)

const StyledSearchButton = styled(Button)(
  ({ theme }) => `
  min-width: 81px;
  & .MuiButton-startIcon {
    margin-right: ${theme.spacing(0.5)};
  }
`
)

const FreeTryButton = styled(Button)(
  ({ theme }) => `
  padding: ${theme.spacing(3)} ${theme.spacing(6.5)};
  align-self: center;
`
)

const ListSearchKeywordsWrapper = styled(Stack)(
  ({ theme }) => `
  padding: 0 ${theme.spacing(5)};
  padding-bottom: ${theme.spacing(7.5)};
  a {
    color: ${theme.primary.base};
  }
`
)

const CancelSearchButton = styled(IconButton)(
  ({ theme }) => `
  width: 36px;
  height: 36px;
  background: ${theme.tertiary.darkness1};
  &:hover {
    background: ${theme.tertiary.darkness2};
  }
`
)
