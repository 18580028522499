import React, { useCallback, useMemo, useState, FC } from 'react'
import Link from 'next/link'
import { Box, Menu, MenuItem, Typography, Chip, Button, styled } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { headerMatching as t } from 'public/locales/ja/components/elements/headerMatching'
import { useAuth } from 'context/authContext'
import { UserRole } from 'types/user'
import { RoleBaseMenu } from 'components/modules/HeaderMatching'
import { useWebchat } from 'hooks/webchat'
import { DesktopOnly } from 'components/elements/DesktopOnly'
import { MobileOnly } from 'components/elements/MobileOnly'
import { CURRENT_PROJECT_ID } from 'const/coordinator'

type MyPageMenuProps = {
  isSellerLp?: boolean
}

export const MyPageMenu: FC<React.PropsWithChildren<MyPageMenuProps>> = ({ isSellerLp }) => {
  const { user } = useAuth()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { isRead } = useWebchat()
  const openMenu = Boolean(anchorEl)

  const titleWebChat = useMemo(() => `${t.menu.mypage.items.chat.title}${t.menu.mypage.items.chat.title2}`, [])

  const buyerMenu = useMemo<RoleBaseMenu[]>(
    () => [
      {
        title: t.menu.mypage.items.dashBoard.title,
        link: '/buyer',
      },
      {
        title: t.menu.mypage.items.deal.title,
        link: '/buyer/deal-list',
      },
      {
        title: titleWebChat,
        link: '/buyer/webchat',
        isReadMessage: isRead,
      },
      {
        title: t.menu.mypage.items.settings.title,
        link: '/buyer/settings',
      },
    ],
    [isRead, titleWebChat]
  )

  const sellerMenu = useMemo<RoleBaseMenu[]>(
    () => [
      {
        title: t.menu.mypage.items.dashBoard.title,
        link: '/seller',
      },
      {
        title: t.menu.mypage.items.deal.title,
        link: '/seller/deal-list',
      },
      {
        title: t.menu.mypage.items.publish.title,
        link: '/seller/project',
      },
      {
        title: titleWebChat,
        link: '/seller/webchat',
        isReadMessage: isRead,
      },
      {
        title: t.menu.mypage.items.settings.title,
        link: '/seller/settings',
      },
    ],
    [isRead, titleWebChat]
  )

  const coordinatorMenu = useMemo<RoleBaseMenu[]>(
    () => [
      {
        title: t.menu.mypage.items.dashBoard.title,
        link: '/coordinator',
        currentProjectLink: (id: string) => `/coordinator/projects/${id}/home`,
      },
      {
        title: t.menu.mypage.items.publish.title,
        link: '/coordinator/switch_project',
        currentProjectLink: (id: string) => `/coordinator/projects/${id}`,
      },
      {
        title: t.menu.mypage.items.deal.title,
        link: '/coordinator/deal-list',
        currentProjectLink: (id: string) => `/coordinator/projects/${id}/deal-list`,
      },
      {
        title: titleWebChat,
        link: '/coordinator/webchat',
        isReadMessage: isRead,
        currentProjectLink: (id: string) => `/coordinator/webchat?projectId=${id}`,
      },
      {
        title: t.menu.mypage.items.settings.title,
        link: '/coordinator/settings',
        currentProjectLink: (id: string) => `/coordinator/settings?projectId=${id}`,
      },
    ],
    [isRead, titleWebChat]
  )

  const handleOpenMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const getMenuList = useCallback(
    (userRole?: UserRole) => {
      if (userRole === UserRole.Buyer) return buyerMenu
      if (userRole === UserRole.Seller) return sellerMenu
      if (userRole === UserRole.Coordinator) return coordinatorMenu
      return []
    },
    [buyerMenu, sellerMenu, coordinatorMenu]
  )

  // 専門家で支援先選択中はそれぞれの設定画面のパスにクエリ: projectIdが必要
  const getItemLink = useCallback((item: RoleBaseMenu) => {
    const currentProjectId = localStorage.getItem(CURRENT_PROJECT_ID)
    if (!currentProjectId) return item.link
    return item?.currentProjectLink ? item?.currentProjectLink(currentProjectId) : '/coordinator'
  }, [])

  return (
    <Box>
      {isSellerLp ? (
        <Box onClick={handleOpenMenu} sx={{ cursor: 'pointer' }}>
          <Typography variant={'body2'} fontWeight={700} sx={{ fontSize: 16 }}>
            {t.myPage}
          </Typography>
        </Box>
      ) : (
        <>
          <MobileOnly>
            <Button sx={{ px: 1 }} variant="contained" size="large" onClick={handleOpenMenu}>
              <Typography fontWeight={700} variant={'body2'}>
                {t.myPage}
              </Typography>
            </Button>
          </MobileOnly>
          <DesktopOnly>
            <Box onClick={handleOpenMenu} sx={{ cursor: 'pointer' }}>
              <Typography variant={'body2'}>{t.myPage}</Typography>
            </Box>
          </DesktopOnly>
        </>
      )}
      <StyleMenu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {getMenuList(user?.role).map((item) => (
          <Link key={item.title} href={getItemLink(item)} passHref>
            <MenuItem>
              <Typography variant={'body2'} fontWeight={700}>
                {item.title}
                {item.title == titleWebChat && !item.isReadMessage && <DotStyled fontSize={'small'} />}
              </Typography>
              {item?.status && <ProjectStatusTag label={item.status} />}
            </MenuItem>
          </Link>
        ))}
      </StyleMenu>
    </Box>
  )
}

const StyleMenu = styled(Menu)(
  ({ theme }) => `
  & .MuiPaper-root {
    box-shadow: 2px 2px 4px 2px #eee6;
    min-width: 208px;
  }
  border-radius: ${theme.spacing(1.25)};
  margin-top: ${theme.spacing(2)};
  a {
    text-decoration: none;
    color: ${theme.tertiary.darkness3};
  }
  li {
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
    justify-content: space-between;
  }
`
)

const ProjectStatusTag = styled(Chip)(
  ({ theme }) => `
  background: ${theme.primary.lightness3};
  border-radius: ${theme.spacing(0.75)};
  font-size: 12px;
`
)
const DotStyled = styled(FiberManualRecordIcon)(
  ({ theme }) => `
  color: ${theme.danger.base}
  `
)
