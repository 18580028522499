import { metaData } from 'public/locales/ja/metaData'
import { footerMatching } from 'public/locales/ja/components/elements/footerMatching'
import { menu } from 'public/locales/ja/components/common/menu'
import { project } from 'public/locales/ja/project'

export const headerMatching = {
  menu,
  project,
  footerMatching,
  metaData,
  title: '閲覧権限がありません',
  altLogo: 'M&Aナビ',
  search: {
    placeHolder: 'キーワードで検索（例：AI事業、学習塾）',
    placeHolderMobile: '入力して下さい',
  },
  button: {
    signIn: 'ログイン',
    register: 'サービス登録',
    search: '検索',
    cancel: '閉じる',
    freeTry: '会員登録（無料）',
    myPage: 'マイページ',
  },
  listSearchKeywords: {
    title: 'よく検索されるキーワード',
    surplus: '黒字',
    pets: 'ペット',
    beauty: '美容',
    overseas: '海外',
    location: '好立地',
  },
  saleDeal: {
    title: '売却案件',
    listOfSales: '売却案件一覧',
    history: '閲覧履歴',
  },
  myPage: 'マイページ',
}
